<template>
  <f7-page id="chat-window" style="position: relative">
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_CUSTOMER_SUPPORT')" type="back" :hidechat="true" :search="false" :cart="false" />
    </template>

    <f7-photo-browser :photos="photos" id="popup" ref="popup" theme="dark" type="popup" :toolbar="false" :popup-close-link-text="$t.getTranslation('BTN_CLOSE')" :routable-modals="false"></f7-photo-browser>

    <f7-popup id="pop-up-prod" class="options-popup" push>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click="closePopup('pop-up-prod')">
            <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
          </f7-link>
        </f7-nav-left>

        <f7-nav-right>
          <f7-link class="searchbar-enable" data-searchbar=".prodsearch">
            <font-awesome-icon :icon="['far', 'search']" fixed-width />
          </f7-link>
        </f7-nav-right>

        <f7-searchbar v-model:value="formData.Search.Product" class="prodsearch" expandable :placeholder="$t.getTranslation('LBL_SEARCH')" :clear-button="true" @keyup.enter="getProducts"></f7-searchbar>
      </f7-navbar>

      <div class="list-wrap list-search-max-height">
        <template v-for="product in Products" :key="'prditem_' + product.ProductId">
          <ProductListCardComponent :data="product" :isShop="true" :category="true" style="width: 90%; margin: 0; border: none" :chatsend="(ProductKey) => onSubmit(ThreadKey, ProductKey, 'PRODUCT', 'prod')" />
        </template>
      </div>
    </f7-popup>

    <f7-popup id="pop-up-order" class="options-popup" push>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click="closePopup('pop-up-order')">
            <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
          </f7-link>
        </f7-nav-left>

        <f7-nav-right>
          <f7-link class="searchbar-enable" data-searchbar=".ordersearch">
            <font-awesome-icon :icon="['far', 'search']" fixed-width />
          </f7-link>
        </f7-nav-right>

        <f7-searchbar v-model:value="formData.Search.Order" class="ordersearch" expandable :placeholder="$t.getTranslation('LBL_SEARCH')" :clear-button="true" @keyup.enter="getOrders"></f7-searchbar>
      </f7-navbar>

      <div class="list-wrap list-search-max-height">
        <template v-for="order in Orders" :key="'ord_' + order.OrderId">
          <OrderItemCardComponent :hidestatus="true" :data="order" :chatsend="(OrderKey) => onSubmit(ThreadKey, OrderKey, 'ORDER', 'order')" />
        </template>
      </div>
    </f7-popup>

    <f7-sheet class="attachment-sheet" swipe-to-close backdrop>
      <f7-page-content>
        <div class="share-container">
          <div class="title">
            <h1>{{ $t.getTranslation("LBL_ADD_ATTACHMENT") }}</h1>
          </div>

          <f7-button fill @click="selectImage($refs)">{{ $t.getTranslation("LBL_ATTACH_IMAGE") }}</f7-button>
          <f7-button fill style="margin-top: 10px" @click="showAttachment('prod')">{{ $t.getTranslation("LBL_SEND_PRODUCT") }}</f7-button>
          <f7-button fill style="margin-top: 10px" @click="showAttachment('order')">{{ $t.getTranslation("LBL_SEND_ORDER") }}</f7-button>
        </div>
      </f7-page-content>
    </f7-sheet>

    <div v-if="ShowSend" class="chat-featured-container">
      <div class="chat-featured-value">
        <ProductListCardComponent v-if="ShowSend.Mode == 'PRODUCT'" style="width: 90%; margin: 0; border: none" :data="ShowSend.Item" :category="true" />
        <OrderItemCardComponent v-if="ShowSend.Mode == 'ORDER'" style="width: 90%; margin: 0; border: none" :data="ShowSend.Item" :back="true" :hidestatus="true" />
      </div>
      <div class="chat-featured-options">
        <f7-link class="chat-featured-send" @click="SendNow">
          {{ $t.getTranslation("BTN_SEND") }}
        </f7-link>
        <f7-link class="chat-featured-cancel" @click="CancelSend">
          {{ $t.getTranslation("BTN_CANCEL") }}
        </f7-link>
      </div>
    </div>

    <div class="messages" style="padding-left: 10px; padding-right: 10px">
      <f7-messages-title v-if="Loaded && !MsgList.length">
        {{ $t.getTranslation("LBL_WELCOME_TO_PJF_LIVE_CHAT") }}
      </f7-messages-title>

      <f7-messages-title v-if="HavePrevious">
        <f7-link @click="loadPrev">
          {{ $t.getTranslation("LBL_LOAD_PREVIOUS_MESSAGES") }}
        </f7-link>
      </f7-messages-title>

      <div class="message" :class="{ 'message-received': item.UserKey != userInfo?.UserKey, 'message-sent': item.UserKey == userInfo?.UserKey }" v-for="item in MsgList" :key="item.ThreadMsgKey">
        <div class="message-avatar" :style="{ 'background-image': $h.getStyleImage(item.User?.Image, 'USER') }"></div>

        <div class="message-content">
          <div class="message-bubble" v-if="item.ThreadMsgTypeCode == 'TEXT'">
            <div class="message-text">{{ item.Content }}</div>
          </div>

          <div class="message-bubble" v-if="item.ThreadMsgTypeCode == 'SYSTEM'">
            <div class="message-text">{{ $h.replaceData($t.getTranslation(item.Content), item.Data) }}</div>
          </div>

          <ProductListCardComponent v-if="item.ThreadMsgTypeCode == 'PRODUCT'" :data="item.Product" :category="true" style="max-width: 80%" />
          <OrderItemCardComponent v-if="item.ThreadMsgTypeCode == 'ORDER'" :data="item.Order" class="min-width-chat" :back="true" :hidestatus="true" style="margin: 0px" />
          <img @click="viewImage($refs, item.Content)" v-if="item.ThreadMsgTypeCode == 'IMAGE'" :src="$h.getImage(item.Content, 'CHAT')" class="chat-image-msg" />
          <div class="message-footer">
            <span v-if="item?.User?.UserId" style="display: block; opacity: 0.5; font-size: 10px">UserID: {{ item.User.UserId }}</span>
            {{ $h.formatDateTime(item.LastCreated) }}
            <span v-if="item.ThreadMsgTypeCode == 'SYSTEM'"> - {{ $t.getTranslation("LBL_SYSTEM_GENERATED_MSG") }}</span>
          </div>
        </div>
      </div>
    </div>

    <f7-messagebar v-model:value="messageText" ref="chat" :placeholder="$t.getTranslation('LBL_MESSAGE_CONTENT')">
      <template #inner-start>
        <f7-link @click="attach">
          <font-awesome-icon :icon="['far', 'plus']" fixed-width />
        </f7-link>
      </template>

      <f7-link @click="onSubmit(ThreadKey)">
        <font-awesome-icon :icon="['fas', 'paper-plane']" fixed-width />
      </f7-link>
    </f7-messagebar>

    <input ref="fileInput" capture="user" type="file" accept="image/*" @change="uploadImage($event)" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted, computed, inject, reactive, defineAsyncComponent } from "vue";
import _ from "lodash";
import { socket } from "@/utils/socket.js";
import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { f7 } from "framework7-vue";
import { useStore } from "@/store";

// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
// import OrderItemCardComponent from "@/components/cards/OrderItemCardComponent.vue";
// import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";

const ProductListCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list-card" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue"));
const OrderItemCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "order-item-card" */ /* webpackMode: "lazy" */ "@/components/cards/OrderItemCardComponent.vue"));
const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));

export default defineComponent({
  name: "ChatViewPage",
  components: {
    ProductListCardComponent,
    OrderItemCardComponent,
    ProductNavigationComponent,
  },
  props: {
    f7route: Object,
  },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();

    const userInfo = computed(() => store.getters["user/getData"]);
    const ThreadKey = ref(null);
    const MsgList = ref([]);
    const HavePrevious = ref(false);
    const ShowSend = ref(null);
    const Loaded = ref(false);

    const formData = reactive({
      Search: {
        Order: "",
        Product: "",
      },
    });

    const SendNow = async (isConcierge) => {
      let ContentType = null;
      let Content = null;
      let endpoint = `/chat/send`;
      if (isConcierge) {
        ContentType = "SYSTEM";
        Content = "SYSTEM_CONCIERGE";
      } else {
        ContentType == "PRODUCT" ? ShowSend.value.Item.ProductKey : ShowSend.value.Item.OrderKey;
        ContentType = ShowSend.value.Mode;
      }

      if (Content == "SYSTEM_CONCIERGE" && isConcierge) {
        endpoint = "/chat/send/concierge";
      }

      let ret = await post(endpoint, {
        ThreadKey: ThreadKey.value,
        Content: Content,
        ContentType: ContentType,
      });

      //$f7router.navigate({ name: "chat" }, { reloadCurrent  : true });
      ShowSend.value = null;
      if (isConcierge) {
        store.dispatch("user/setData", {
          IsConcierge: 0,
        });
      }
    };

    const CancelSend = async () => {
      ShowSend.value = null;
      //$f7router.navigate({ name: "chat" }, { reloadCurrent  : true });
    };

    const markRead = async () => {
      await post("/chat/read/all", { ThreadKey: ThreadKey.value });
    };

    const getOrder = async () => {
      if (!props?.f7route?.query?.order) return;

      let ret = await get("/order/view", { key: props.f7route.query.order });
      ShowSend.value = {
        Mode: "ORDER",
        Item: ret.Order,
      };
    };

    const getProduct = async () => {
      if (!props?.f7route?.query?.product) return;

      let ret = await get("/mobile/product/list", { key: props.f7route.query.product });
      ShowSend.value = {
        Mode: "PRODUCT",
        Item: ret.Product,
      };
    };

    const loadPrev = async () => {
      HavePrevious.value = false;
      //get now
      getAll(MsgList.value[0].ThreadMsgKey);
    };

    const receivedMessage = async (data) => {
      if (data.Room != ThreadKey.value) return;
      MsgList.value.push(data.Msg); //after push scroll to bottom
      markRead();
      scrollToBottom();
    };

    const scrollToBottom = async () => {
      setTimeout(() => {
        let parent = document.getElementById("chat-window");
        let child = parent.querySelector(".page-content");
        child.scrollTop = child.scrollHeight;
      }, 200);
    };

    const getAll = async (LastKey) => {
      let ret = await get("/chat/history", { LastKey: LastKey, ThreadKey: ThreadKey.value });
      HavePrevious.value = ret.HavePrevious ? true : false;
      if (ret.MsgList && ret.MsgList.length) {
        if (!LastKey) {
          _.each(ret.MsgList.reverse(), (Msg) => {
            MsgList.value.push(Msg);
          });
          //scroll to bottom
          scrollToBottom();
        } else {
          _.each(ret.MsgList, (Msg) => {
            MsgList.value.unshift(Msg);
          });
        }
      }

      Loaded.value = true;
    };

    const setup = () => {
      socket.assignSocketCallback("RECEIVED_MSG", receivedMessage);
      socket.join(ThreadKey.value);
      //get history here and call markRead
      markRead();
      getAll();
    };

    const init = async () => {
      let ret = await get("/chat/thread", {});
      ThreadKey.value = ret.ThreadKey;
      setup();
      getProduct(); //get if product
      getOrder();
      //check if there's a store for concierge if yes call sendNow(true) to send conceirge..
      if (userInfo.value?.IsConcierge > 0) {
        SendNow(true);
      }
    };

    onUnmounted(() => {
      socket.unassignSocketCallback("RECEIVED_MSG");
      socket.leave(ThreadKey.value);
    });

    //for attachments
    const Products = ref([]);
    const Orders = ref([]);

    const uploadImage = async (event) => {
      let input = event.target;
      if (input?.files?.[0]) {
        const formData = new FormData();
        formData.append("file", input?.files?.[0]);
        let ret = await post("/chat/upload", formData);
        _.each((ret && ret.files) || [], async (r) => {
          let send = await post("/chat/send", {
            ThreadKey: ThreadKey.value,
            Content: r,
            ContentType: "IMAGE",
          });
        });
      }
    };

    const selectImage = (refs) => {
      f7.sheet.get(".attachment-sheet").close();
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    const attach = () => {
      f7.sheet.get(".attachment-sheet").open();
    };

    const getOrders = async () => {
      let ret = await get("/order/list", {
        search: formData.Search.Order,
      });
      Orders.value = ret?.List;
    };

    const getProducts = async () => {
      let res = await get(`/mobile/product/public/list`, {
        page: 1,
        size: 25,
        SearchField: "MULTIPLE",
        SearchType: "LIKE",
        SearchValue: formData.Search.Product,
        LanguageCode: $t.getLanguage(),
      });
      Products.value = res.data;
    };

    const showAttachment = (mode) => {
      f7.popup.get("#pop-up-" + mode).open();
      f7.sheet.get(".attachment-sheet").close();

      //for refresh
      getProducts();
      getOrders();
    };

    const photos = ref([]);
    const viewImage = ($refs, image) => {
      photos.value = [helpers.getImage(image, "CHAT")];
      setTimeout(() => {
        $refs.popup.open();
      }, 300);
    };

    const closePopup = (id) => {
      f7.popup.get(`#${id}`).close();
    };

    onMounted(init);
    return { closePopup, viewImage, photos, formData, selectImage, uploadImage, showAttachment, Orders, getOrders, Products, getProducts, ThreadKey, attach, Loaded, userInfo, MsgList, CancelSend, HavePrevious, loadPrev, ShowSend, SendNow };
  },
  data() {
    return {
      messageText: null,
    };
  },
  methods: {
    async onSubmit(ThreadKey, Content, ContentType, opt) {
      let toSend = this.messageText ? this.messageText.trim() : null; //replace(/\n/g, '<br>')

      let ret = await post("/chat/send", {
        Source: this?.$props?.f7route?.query?.utm_source || "shop",
        ThreadKey: ThreadKey,
        Content: Content || toSend,
        ContentType: ContentType || "TEXT",
      });

      this.messageText = null;

      if (opt) {
        f7.popup.get("#pop-up-" + opt).close();
      }
    },
  },
});
</script>
<style scoped>
.chat-image-msg {
  max-width: 80%;
  border-radius: 5px;
  cursor: pointer;
}

input[type="file"] {
  opacity: 0;
  position: fixed;
  bottom: 0px;
  z-index: -1;
}

.list-search-max-height {
  max-height: calc(100% - 70px);
  overflow: auto;
}

.message:not(.message-last) .message-avatar {
  opacity: 1;
}
.message-avatar {
  border: 1px solid #ccc;
  align-self: flex-start;
}
.min-width-chat {
  min-width: 210px;
  width: 100%;
}

.message {
  max-width: 80%;
}

.message-text {
  white-space: pre-wrap;
}

.message-footer {
  margin-top: 5px;
  margin-bottom: 5px;
}

.chat-toolbar {
  display: flex;
  flex-direction: row;
  background: #ccc;
  height: 100%;
  width: 100%;
}

.chat-featured-container {
  display: flex;
  position: fixed;
  top: 100px;

  z-index: 999;
  background: #fff;
  width: 90%;
  max-width: 390px;

  left: 50%;
  transform: translate(-50%, 0);

  border-radius: 5px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  overflow: hidden;
}
.chat-featured-value {
  flex: 1;
}
.chat-featured-options {
  display: flex;
  width: 100px;
  flex-direction: column;
}
.chat-featured-send {
  flex: 1;
  color: #fff;
  background: #0c4d8f;
}
.chat-featured-cancel {
  flex: 1;
  color: #222;
  background: #f7f7f7;
}
</style>
